import React from 'react'
import Img from 'gatsby-image'
import { graphql } from 'gatsby'
import Marco from '../components/marco'
import moment from '../utiles/moment-en-castellano'

import { css } from '@emotion/core'
import styled from '@emotion/styled'

const TituloArticulos = styled.h3`
  color: #16455a;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-size: 3.5rem;
  font-family: 'Satisfy';
  font-weight: 300;
`

const SideBarIzquierdo = styled.div`
  flex: 2.1;
  @media (max-width: 900px) {
    flex: 1.5;
  }
  @media (max-width: 500px) {
    flex: 1;
  }
`
const SideBarDerecho = styled.div`
  flex: 2.1;
  @media (max-width: 900px) {
    flex: 1.5;
  }
  @media (max-width: 500px) {
    flex: 1;
  }
`
const ContenedorBlog = styled.div`
  flex: 3.9;
  @media (max-width: 900px) {
    flex: 9;
  }
  @media (max-width: 500px) {
    flex: 8;
  }
  flex-direction: column;
  background-color: white;
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  padding-right: 2.5rem;
  padding-left: 2.5rem;
  padding-top: 1.5rem;
`

const LayoutEntradas = styled.div`
  display: flex;
`
const Entradas = ({ children }) => (
  <LayoutEntradas>
    <SideBarIzquierdo />
    <ContenedorBlog>{children}</ContenedorBlog>
    <SideBarDerecho />
  </LayoutEntradas>
)

const EntradaBlog = styled.div`
  display: flex;
  flex: 
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  margin-right: 1rem;
  margin-bottom: 1.7rem;
  align-items: flex-start;
  background-color: white;
  &:first-of-type {
    padding-top: 1rem;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 1rem;
    border-bottom: 1px solid #d0d0d0;
    margin-right: 1rem;
  }
`

const fotomin = css`
  object-fit: contain;
  width: 120px;
  @media (max-width: 768px) {
    width: 50%;
  }
`
const miniatura = css`
  flex: 1;
  padding-top: 3px;
  @media (max-width: 900px) {
    padding-left: 1rem;
  }
`

const Miniatura = ({ fixed }) => (
  <div css={miniatura}>
    <Img css={fotomin} alt="" fixed={fixed} />
  </div>
)

const DescripcionIndice = styled.div`
  flex: 7;
  font-size: 12px;
  background-color: white;
  color: black;
  padding-left: 2rem;
  padding-right: 1rem;
  & a {
    color: red;
    text-decoration: none;
  }
  @media (max-width: 900px) {
    padding-right: 2rem;
  }
  @media (max-width: 768px) {
    padding-left: 1rem;
    flex: 1;
  }
  @media (max-width: 500px) {
    flex: 1;
  }
`
const TextoDescripcion = styled.div`
  margin-bottom: 6px;
`
const Entrada = ({ fixed, tit, tags, fecha, resumen, articulo }) => (
  <EntradaBlog>
    <Miniatura fixed={fixed} />
    <DescripcionIndice>
      <TituloEntrada>{tit}</TituloEntrada>
      <TextoDescripcion>
        {resumen}
        <a href={'/' + articulo}> [Leer más...]</a>
      </TextoDescripcion>
      <TagsYFecha tags={tags} fecha={fecha} />
    </DescripcionIndice>
  </EntradaBlog>
)
const TituloEntrada = styled.h3`
  margin-top: 0;
  font-weight: 600;
`
// #region Tags y fecha
const Tags = styled.div`
  width: 60%;
`
const Fecha = styled.div`
  width: 40%;
  text-align: right;
  color: #808080;
  font-family: Satisfy;
  padding-right: 8;
`
const tyf = css`
  display: flex;
`
const TagsYFecha = ({ tags, fecha }) => (
  <div css={tyf}>
    <Tags>{tags}</Tags>
    <Fecha>{fecha}</Fecha>
  </div>
)
// #endregion
// #region GraphQL
export const query = graphql`
  {
    allArticulosYaml {
      edges {
        node {
          articulo
          fecha
          resumen
          tipo
          titulo
          nombre
          tags
          origen {
            childImageSharp {
              fixed(width: 120) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  }
`
// #endregion
const BlogVlog = ({ data }) => {
  const lista = data.allArticulosYaml.edges.map(x => x.node)

  const aFecha = x => {
    moment.locale('es')
    return moment(x.fecha).format('D[ de ] MMMM[, ] YYYY')
  }
  return (
    <Marco>
      <Entradas>
        <TituloArticulos>Aquí tienes mis artículos</TituloArticulos>
        {lista.map((x, indice) => {
          const fixed = x.origen.childImageSharp.fixed
          const tit = x.titulo
          const tags = x.tags
          const fecha = aFecha(x)
          const resumen = x.resumen
          const articulo = x.articulo
          return (
            <Entrada
              key={indice}
              fixed={fixed}
              tit={tit}
              tags={tags}
              fecha={fecha}
              resumen={resumen}
              articulo={articulo}
            />
          )
        })}
      </Entradas>
    </Marco>
  )
}

export default BlogVlog
